import {
  InstrumentAction,
  InstrumentEvent,
  InstrumentStatus,
  InstrumentTagType,
  InstrumentUploaderType,
} from '@finverity/graphql';
import { FinButtonAppearance } from '@finverity/ui-kit';

import { InstrumentApprovalConfigs, InstrumentApprovalMakerSubscriptionEvents } from '../../../types';
import { InstrumentApprovalMakerDataGridColId } from '../../instrument-approval-maker-container/constants';

export enum FunderInstrumentApprovalMakerFilterIdentifier {
  PendingFunderApprovalMaker = 'PENDING_FUNDER_APPROVAL_MAKER',
  NotFundable = 'NOT_FUNDABLE',
}

export const FUNDER_INSTRUMENT_APPROVAL_MAKER_CONFIGS: InstrumentApprovalConfigs = {
  companyType: InstrumentUploaderType.DealManagerFunder,
  pageTitle: 'Funder Approval (Maker)',
  actionsDefs: [
    {
      buttonText: 'Review & Approve',
      buttonSvgIconName: 'fvIconInstrumentApprovalReview',
      buttonAppearance: FinButtonAppearance.Primary,
      actionType: InstrumentAction.FunderApprovalMakerApprove,
      disabled: true,
      disabledTooltip: null,
    },
    {
      buttonText: 'Reject',
      buttonSvgIconName: 'fvIconInstrumentApprovalReject',
      buttonAppearance: FinButtonAppearance.OutlineAccent,
      actionType: InstrumentAction.FunderApprovalMakerReject,
      disabled: true,
      disabledTooltip: null,
    },
  ],
  filtersDefs: [
    {
      identifier: FunderInstrumentApprovalMakerFilterIdentifier.PendingFunderApprovalMaker,
      label: 'Pending Funder Approval (Maker)',
      params: {
        statuses: [InstrumentStatus.FunderApprovalPendingMakerConfirmation],
        tags: {
          notIncludes: [
            InstrumentTagType.Deleted,
            InstrumentTagType.NotFundable,
            InstrumentTagType.NoEligibleFundingWindow,
          ],
        },
      },
      count: 0,
      hiddenColumns: [],
    },
    {
      identifier: FunderInstrumentApprovalMakerFilterIdentifier.NotFundable,
      label: 'Not Fundable',
      params: {
        statuses: [InstrumentStatus.FunderApprovalPendingMakerConfirmation],
        tags: {
          notIncludes: [InstrumentTagType.Deleted],
          includes: [InstrumentTagType.NotFundable, InstrumentTagType.NoEligibleFundingWindow],
        },
      },
      count: 0,
      hiddenColumns: [
        InstrumentApprovalMakerDataGridColId.DisbursementDate,
        InstrumentApprovalMakerDataGridColId.Tenor,
        InstrumentApprovalMakerDataGridColId.RepaymentDate,
        InstrumentApprovalMakerDataGridColId.AdvanceRate,
        InstrumentApprovalMakerDataGridColId.PrincipalAmount,
        InstrumentApprovalMakerDataGridColId.DisbursementAmount,
        InstrumentApprovalMakerDataGridColId.RepaymentAmount,
        InstrumentApprovalMakerDataGridColId.NetUnfinancedBalance,
        InstrumentApprovalMakerDataGridColId.SellerFeesInAdvance,
        InstrumentApprovalMakerDataGridColId.PayerFeesInAdvance,
        InstrumentApprovalMakerDataGridColId.SellerFeesInArrears,
        InstrumentApprovalMakerDataGridColId.PayerFeesInArrears,
        InstrumentApprovalMakerDataGridColId.TotalSellerFees,
        InstrumentApprovalMakerDataGridColId.TotalPayerFees,
        InstrumentApprovalMakerDataGridColId.TotalFees,
      ],
    },
  ],
  subscriptionEvents: <InstrumentApprovalMakerSubscriptionEvents>{
    approvedSuccess: [InstrumentEvent.FunderApprovalCheckerPending],
    approvedFailure: [InstrumentEvent.FunderApprovalMakerPending],
    rejected: [InstrumentEvent.FunderApprovalMakerRejected],
    updated: [InstrumentEvent.InstrumentEdited],
    removed: [InstrumentEvent.InstrumentDeleted],
  },
};
